import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import otide from '../views/otide.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'otide',
    component:otide
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
