
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'RELACS',
  components: {
  },
  
  data: function(){
    return{
      Open:false,
      SignUpURL:"",
      PolicyURL:"",
      InitialValue:"",
      ReachFldOn:false,
      MenuFldOn:false
    }
  },

  methods: {
    JumpSignUpURL(){
      this.SignUpURL = "https://ac.pe-k.site/cl/08IS5ffI924f8860/?bid=43be43f6dE35a330"
      window.location.href = this.SignUpURL
    },

    ReachOpen(){
      this.ReachFldOn = true
    },

    ReachClose(){
      this.ReachFldOn = false
    },

    MenuOpen(){
      this.MenuFldOn = true
    },

    MenuClose(){
      this.MenuFldOn = false
    },

    JumpPolicy(){
      this.PolicyURL = "https://ouchide.clinic/privacy"
      window.location.href = this.PolicyURL
    },

  },

  mounted(){
    setTimeout(() => {
      this.Open = true;
    }, 1);

    const setFillHeight = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
          }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillHeight);

    // 初期化
    setFillHeight();

    const setFillWidth = () => {
            const vh2 = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vh2', `${vh2}px`);
          }

    // 画面のサイズ変動があった時に高さを再計算する
    window.addEventListener('resize', setFillWidth);

    // 初期化
    setFillWidth();
  }
});
